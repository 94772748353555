import { FC, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { ModalState } from '../reducer';
import { ModalHeader } from '../index';
import { Form } from '../../../shared';
import { Dropzone } from '../../dropzone';
import { FileWithPath } from 'react-dropzone/.';
import { FileIcon2 } from '../../../assets/icons';

interface IUploadChennels extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const UploadChannels: FC<IUploadChennels> = ({ loading, params, hideModal }) => {
  const [isFileAdded, setIsFileAdded] = useState(false);

  const dropzoneRef = useRef<{
    openFileDialog: () => void;
    handleUpload: () => void;
    hasAcceptedFiles: boolean;
    fileRejections: any[];
  }>(null);

  const triggerUpload = () => {
    dropzoneRef.current && dropzoneRef.current.handleUpload();
  };

  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    setIsFileAdded(acceptedFiles.length > 0);
  };

  const handleOpenFileDialog = () => {
    dropzoneRef.current && dropzoneRef.current.openFileDialog();
  };
  const defaultValues: any = useMemo(() => ({}), [params]);
  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={'Upload YouTube Channels File'} close={hideModal} />

      <Form className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0" defaultValues={defaultValues} onSubmit={() => {}}>
        {({ formState: { isSubmitting } }) => (
          <>
            <Dropzone
              text={'Select a CSV file to import'}
              platform={params.platform}
              campaign_id={params.campaign_id}
              accept={{
                'text/csv': ['.csv'],
              }}
              handleDrop={handleDrop}
              ref={dropzoneRef}
              onSuccess={(payload) => {
                hideModal();
                params.onSuccess(payload);
              }}
            />
            <div className="grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center uppercase justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>

              {isFileAdded && (
                <button
                  type="submit"
                  className={cn(
                    'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                    { disabled: isSubmitting }
                  )}
                  disabled={isSubmitting}
                  onClick={triggerUpload}
                >
                  <p>Upload</p>
                </button>
              )}

              {!isFileAdded && (
                <button
                  type="submit"
                  className={cn(
                    'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                    { disabled: isSubmitting }
                  )}
                  disabled={isSubmitting}
                  onClick={handleOpenFileDialog}
                >
                  <FileIcon2 />
                  <p>Select</p>
                </button>
              )}
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
