import { FC, useState, useMemo, useCallback, forwardRef, useImperativeHandle, useRef } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useSMPlatforms } from 'hooks/useSMPlatforms';
import { useParams } from 'react-router-dom';
import { SingleValue, StylesConfig } from 'react-select';
import { useVirtual } from 'react-virtual';
import { useBlockLayout, useRowSelect, useTable, useResizeColumns } from 'react-table';
import { cloneDeep, isEqual } from 'lodash';
import cn from 'classnames';
import { colors } from 'variables';
import { Platform } from 'services';
import { platformConfig } from 'utils/common';
import { formatNumber } from 'utils/helper';
import { Breadcrumbs, HSelect, RSelect, TableHeaderSort, TableCellTruncate, TableResizer, TableHeaderTruncate, ActionMenu } from 'shared';
import {
  addBulkToSelected,
  campaignActions,
  createCampaignSelectedChannelThunk,
  fetchCampaignChannelsThunk,
  fetchCampaignSelectedChannelsThunk,
  selectCampaign,
  selectCampaignChannels,
  selectCampaignInfluencersSelectedIds,
  selectCampaignInfluencersSettingsTableFilter,
  selectCampaignInfluencersSettingsTableFilterHiddenColumns,
  selectCampaignInfluencersSettingsTableFilterResizing,
  selectCampaignLoadingToHeader,
  selectCampaignPlatform,
  selectCampaignSearch,
  selectCampaignSelectedChannelsByInfluencersPlatform,
  selectCampaignTotal,
} from '../reducer';
import { selectAgencies } from 'pages/Admin/reducer';
import {
  IndeterminateCheckbox,
  makeColumnHiddenCheckboxes,
  platformColumns,
  PlatformSelectCustomSingleValue,
  PlatformSelectOption,
  platformSelectStyles,
} from 'pages/Influencers/tabs/InfluencersTab';
import { PopupDescription } from 'components/PopupDescription';
import { HeaderSearchWithSettings, SearchSelectStyles, SearchModeSelectStyles, SearchStyles } from 'components/HeaderSearchWithSettings';
import { Settings } from 'components/Settings';
import { Bars3Icon, LinkIcon, StarSolidIcon } from 'assets/icons';

import './styles.css';
import { showModal } from '../../../components/Modal/reducer';
import { ModalTypes } from '../../../components/Modal';

const searchStyles: StylesConfig<any, true> = {
  ...SearchStyles,
  control: (styles, props) => ({
    ...(SearchStyles?.control ? SearchStyles.control(styles, props) : {}),
    minHeight: '42px !important',
    height: '42px !important',
  }),
};

const searchSelectStyles: StylesConfig<any, false> = {
  ...SearchSelectStyles,
  control: (styles, props) => ({
    ...(SearchSelectStyles?.control ? SearchSelectStyles.control(styles, props) : {}),
    minHeight: '42px !important',
    height: '42px !important',
  }),
};

const searchModeSelectStyles: StylesConfig<any, false> = {
  ...SearchModeSelectStyles,

  control: (styles, props) => ({
    ...(SearchModeSelectStyles?.control ? SearchModeSelectStyles.control(styles, props) : {}),
    minHeight: '42px !important',
    height: '42px !important',
  }),
};

interface IInfluencersProps {}

export const Influencers: FC<IInfluencersProps> = () => {
  const { campaignId } = useParams();

  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaign);
  const channels = useAppSelector(selectCampaignChannels);
  const total = useAppSelector(selectCampaignTotal);
  const platform = useAppSelector(selectCampaignPlatform);
  const search = useAppSelector(selectCampaignSearch);
  const selectedIds = useAppSelector(selectCampaignInfluencersSelectedIds);
  const selectedChannels = useAppSelector(selectCampaignSelectedChannelsByInfluencersPlatform);
  const tableFilters = useAppSelector(selectCampaignInfluencersSettingsTableFilter);
  const hiddenColumns = useAppSelector(selectCampaignInfluencersSettingsTableFilterHiddenColumns);

  const agencies = useAppSelector(selectAgencies);

  const agenciesPrepare = useMemo(() => {
    let agencyObj: any = {};

    if (agencies.length) {
      agencies.forEach((agency: any) => {
        agencyObj[agency.agency_id] = { ...agency };
      });
    }

    return agencyObj;
  }, [agencies]);

  const platformSelectOptions = useSMPlatforms(campaign);

  const [platformSelect, setPlatformSelect] = useState<Platform>(platform);

  const tableRef = useRef<any>(null);

  const handleChangeSelect = (props: any) =>
    dispatch(
      campaignActions.setInfluencersSettings({
        tableFilters: {
          ...tableFilters,
          [platformSelect]: {
            ...tableFilters[platformSelect],
            hiddenColumns: props,
          },
        },
      })
    );

  const handleSelectChange = (newValue: SingleValue<any>, _platform: Platform) => {
    setPlatformSelect(newValue.value);
    dispatch(campaignActions.setPlatform(newValue.value)); // TODO dispatch this action after waiting request response inside 'then()'
    dispatch(fetchCampaignChannelsThunk())
      .unwrap()
      .then(() => {
        tableRef?.current?.resetSelectedRows();
        dispatch(campaignActions.setInfluencersIds([]));
        dispatch(
          campaignActions.setInfluencersSettings({
            tableFilters: {
              ...tableFilters,
              [newValue.value]: {
                ...tableFilters[newValue.value as Platform],
                hiddenColumns: !tableFilters[newValue.value as Platform].hiddenColumns
                  ? makeColumnHiddenCheckboxes(newValue.value, total)
                  : tableFilters[newValue.value as Platform].hiddenColumns,
              },
            },
          })
        );
      })
      .catch(() => {
        dispatch(
          campaignActions.setInfluencersSettings({
            // platform: _platform,
            tableFilters: {
              ...tableFilters,
              [_platform]: {
                ...tableFilters[_platform],
                hiddenColumns: !tableFilters[_platform].hiddenColumns ? makeColumnHiddenCheckboxes(_platform, total) : tableFilters[_platform].hiddenColumns,
              },
            },
          })
        );
      });
  };

  const columns = useMemo(() => {
    const _platformColumns = cloneDeep(platformColumns(total, agenciesPrepare)[platformSelect]);
    _platformColumns[0] = {
      Header: platformConfig[platformSelect].channel_name.name,
      accessor: 'channel_name',
      Cell: ({ row, value }: any) => (
        <div className="flex flex-1 items-center justify-between overflow-hidden">
          <div className="flex items-center truncate space-x-2 overflow-hidden">
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="border-2 border-gray-gm rounded-[2px]" />
            <TableCellTruncate
              linkTo={row.original?.influencer_id != null ? `/campaigns/${campaignId}/${row.original.influencer_id}` : undefined}
              value={value}
            />
            <a href={row.original.channel_url} target="_blank" rel="noopener">
              <LinkIcon />
            </a>
          </div>
          {row.original.selected_id ? (
            <div>
              <StarSolidIcon className="shrink-0 place-self-end" />
            </div>
          ) : null}
        </div>
      ),
      Footer: 'Total',
      width: 220,
    };
    return [..._platformColumns].filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action')) || [];
  }, [hiddenColumns, total, campaignId, agenciesPrepare]);

  const channelsPrepare = useMemo(() => {
    return channels.map((d: any) => {
      const selectedChannel = selectedChannels.find((ch: any) => ch[platformSelect + '_channel_id'] === d[platformSelect + '_channel_id']);
      return selectedChannel ? { ...d, selected_id: selectedChannel.selected_id } : d;
    });
  }, [channels, selectedChannels]);

  const handleApply = (value: any) => {
    dispatch(
      campaignActions.setInfluencersSettings({
        ...search,
        filters: {
          ...search.filters,
          [platform]: value,
        },
      })
    );
    dispatch(fetchCampaignChannelsThunk());
  };

  const handleApplyAdvancesFilters = (props: any) => {
    dispatch(
      campaignActions.setInfluencersSettings({
        ...search,
        advancesFilters: {
          ...search.advancesFilters,
          [platform]: props,
        },
      })
    );
    dispatch(fetchCampaignChannelsThunk());
  };

  useLegacyEffect(() => {
    dispatch(fetchCampaignChannelsThunk());
  }, []);

  // Initialize hiddenColumns
  useLegacyEffect(() => {
    if (!hiddenColumns)
      dispatch(
        campaignActions.setInfluencersSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              hiddenColumns: makeColumnHiddenCheckboxes(platform, total),
            },
          },
        })
      );
  }, [hiddenColumns]);

  return (
    <>
      <HeaderSearchWithSettings
        className="py-4"
        styles={searchStyles}
        searchSelectStyles={searchSelectStyles}
        searchModeSelectStyles={searchModeSelectStyles}
        value={search.keywords || []}
        searchSettings={search}
        advancesFilters={search.advancesFilters[platform] || {}}
        onChange={(value: string[], searchBy: string, searchMode: string, from?: string) => {
          dispatch(
            campaignActions.setInfluencersSettings({
              keywords: value,
              searchBy,
              searchMode,
            })
          );
          if (from === 'searchBy' && !value.length) {
          } else dispatch(fetchCampaignChannelsThunk());
        }}
        onSubmit={handleApplyAdvancesFilters}
      />
      <div className="flex flex-1 flex-col overflow-hidden p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">{`${formatNumber(total ? parseFloat(total.count || 0) : 0)} Influencers Found`}</h1>
          <div className="flex items-center space-x-4">
            <ActionMenu
              name={'Add To Selected'}
              items={[
                {
                  label: `Add Selected Channels ${selectedIds.length ? `(${selectedIds.length})` : ''}`,
                  disabled: !selectedIds.length,
                  onClick: () => {
                    if (selectedIds.length)
                      dispatch(
                        createCampaignSelectedChannelThunk({
                          campaign_id: campaignId || '',
                          data: {
                            sm_platform: platformSelect,
                            channel_ids: selectedIds,
                          },
                        })
                      )
                        .unwrap()
                        .then(() => tableRef?.current?.resetSelectedRows());
                  },
                },
                {
                  label: 'Add All Filtered Channels',
                  disabled: (total ? parseFloat(total.count || 0) : 0) > 3000,
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.AddBulkToSelected,
                        params: {
                          amount: total.count,

                          handleSubmit: async () =>
                            await dispatch(addBulkToSelected({ campaign_id: campaignId || '' }))
                              .unwrap()
                              .then(async () => {
                                await dispatch(fetchCampaignSelectedChannelsThunk(campaignId || ''));
                              }),
                        },
                      })
                    ),
                },
              ]}
            />
            <HSelect
              menuClass="w-44"
              selected={hiddenColumns}
              options={platformColumns(total, agenciesPrepare)[platformSelect].map((d: any) => ({
                id: d.accessor,
                name: platformConfig[platformSelect][d.accessor].name,
              }))}
              onChange={handleChangeSelect}
            >
              {({ open }) => (
                <button
                  type="button"
                  className={cn(
                    'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                    open ? 'bg-blue-b1' : 'bg-white'
                  )}
                >
                  <Bars3Icon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                </button>
              )}
            </HSelect>
            <RSelect
              className="w-[150px]"
              styles={platformSelectStyles}
              defaultValue={platformSelectOptions[platformSelect]}
              name="select-channels"
              isSearchable={false}
              isClearable={false}
              options={Object.keys(platformSelectOptions)
                .filter((d: any) => d !== platformSelect)
                .map((d: any) => platformSelectOptions[d])}
              components={{
                ClearIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: PlatformSelectOption,
                SingleValue: PlatformSelectCustomSingleValue,
              }}
              onChange={(newValue: SingleValue<any>) => handleSelectChange(newValue, platformSelect)}
            />
          </div>
        </div>
        <Breadcrumbs
          paths={[
            { name: `Campaigns`, to: `/campaigns` },
            {
              name: `${campaign.campaign_name || campaignId || ''}`,
              to: '',
            },
          ]}
        />

        <Settings platform={platform} searchChannels={search} handleSubmit={handleApply} />

        <div className="mt-4 flex flex-col overflow-hidden">
          <div className="flex flex-col min-w-full align-middle overflow-hidden">
            <Table ref={tableRef} columns={columns} data={channelsPrepare} platform={platformSelect} />
          </div>
        </div>
      </div>
    </>
  );
};

interface ITableProps {
  data: any[];
  columns: any[];
  platform: Platform;
}

const Table = forwardRef(function Table({ columns, data, platform }: ITableProps, ref: any) {
  const dispatch = useAppDispatch();
  const selectedIds = useAppSelector(selectCampaignInfluencersSelectedIds);
  const total = useAppSelector(selectCampaignTotal);
  const search = useAppSelector(selectCampaignSearch);
  const loading = useAppSelector(selectCampaignLoadingToHeader);
  const tableFilters = useAppSelector(selectCampaignInfluencersSettingsTableFilter);
  const tfResizing = useAppSelector(selectCampaignInfluencersSettingsTableFilterResizing);

  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 130,
      // width: 150,
      // maxWidth: 400,
    }),
    []
  );

  const totalDBRowCount = parseFloat(total.count || 0);
  const totalFetched = data.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (scrollHeight - scrollTop - clientHeight < 300 && !loading && totalFetched < totalDBRowCount) {
          dispatch(
            fetchCampaignChannelsThunk({
              offset: totalFetched,
              isScrollFetch: true,
            })
          );
        }
      }
    },
    [totalFetched, totalDBRowCount, loading]
  );

  const selectedRows = useMemo(() => {
    const ids: any = {};
    data.forEach((d: any, i: number) => {
      if (selectedIds.includes(d.youtube_channel_id || d.twitch_channel_id)) ids[i] = true;
    });
    return ids;
  }, [data]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    toggleAllRowsSelected,
    // @ts-ignore
    state: { selectedRowIds, columnResizing },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // @ts-ignore
      initialState: { selectedRowIds: selectedRows },
      useControlledState: (state) => {
        return useMemo(() => {
          return {
            ...state,
            columnResizing: {
              // @ts-ignore
              ...state?.columnResizing,
              // @ts-ignore
              columnWidths: state?.columnResizing?.headerIdWidths
                ? // @ts-ignore
                  {
                    ...tfResizing,
                    // @ts-ignore
                    ...state.columnResizing?.columnWidths,
                  }
                : tfResizing,
            },
          };
          // @ts-ignore
        }, [state, tfResizing]);
      },
    },
    useBlockLayout,
    useRowSelect,
    useResizeColumns
  );

  useLegacyEffect(() => {
    const ids: string[] = Object.keys(selectedRowIds).map((d: any) => data[d][platform + '_channel_id']);
    if (!isEqual(selectedIds, ids)) dispatch(campaignActions.setInfluencersIds(ids));
  }, [selectedIds, selectedRowIds]);

  useImperativeHandle(
    ref,
    () => ({
      resetSelectedRows: () => toggleAllRowsSelected(false),
    }),
    []
  );

  useLegacyEffect(() => {
    if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
      dispatch(
        campaignActions.setInfluencersSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              resizing: columnResizing.columnWidths,
            },
          },
        })
      );
  }, [columnResizing?.columnWidths]);

  const handleSort = (order_by: string, order_dir: string | 'asc' | 'desc') => {
    // TODO maybe make update search sort after fetch
    dispatch(
      campaignActions.setInfluencersSettings({
        ...search,
        order_by: {
          ...search.order_by,
          [platform]: order_by,
        },
        order_dir: {
          ...search.order_dir,
          [platform]: order_dir,
        },
      })
    );
    dispatch(fetchCampaignChannelsThunk());
  };

  const checkOrderDir = (column: string) => {
    return search.order_by[platform] === column && search.order_dir[platform] === 'desc' ? 'asc' : search.order_dir[platform] === 'asc' ? '' : 'desc';
  };

  //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  useLegacyEffect(() => {
    if (tableContainerRef.current && tableContainerRef.current.scrollTop) tableContainerRef.current.scrollTop = 0;
  }, [search, platform]);

  // Render the UI for your table
  return (
    <div className="overflow-auto shadow rounded-[4px]" onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)} ref={tableContainerRef}>
      <div {...getTableProps()} className="table pr-40">
        <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column: any, hidx: number) => (
                <div
                  {...column.getHeaderProps()}
                  title=""
                  className={cn(
                    'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                    hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                    column?.headerClassName || '',
                    search.order_by[platform] === column.id ? 'bg-gray-g3' : ''
                  )}
                >
                  <div
                    className="flex items-center justify-between px-4 py-2 space-x-1 cursor-pointer"
                    onClick={() => {
                      handleSort(search.order_by[platform] === column.id && !checkOrderDir(column.id) ? '' : column.id, checkOrderDir(column.id));
                    }}
                  >
                    {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                    <div className="flex items-center space-x-2">
                      {column.tooltip && <PopupDescription content={column.tooltip} />}
                      <TableHeaderSort
                        column={column}
                        isSorted={search.order_by[platform] === column.id}
                        isSortedDesc={search.order_dir[platform] === 'desc'}
                      />
                    </div>
                  </div>
                  <TableResizer column={column} />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="tbody bg-white">
          {paddingTop > 0 && (
            <div className="tr">
              <div className="td" style={{ height: `${paddingTop}px` }} />
            </div>
          )}
          {virtualRows.map((virtualRow: any) => {
            const row = rows[virtualRow.index];
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                {row.cells.map((cell: any, bidx: number) => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className={cn(
                        'td relative !flex items-center truncate whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                        bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                        cell.column?.className || ''
                      )}
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {paddingBottom > 0 && (
            <div className="tr">
              <div className="td" style={{ height: `${paddingBottom}px` }} />
            </div>
          )}
        </div>
        <div className="tfoot sticky bottom-0 z-30 bg-white">
          {footerGroups.map((group) => (
            <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
              {group.headers.map((column, fidx) => (
                <div
                  {...column.getFooterProps()}
                  className={cn(
                    'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                    fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                  )}
                >
                  {column.render('Footer')}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
