import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import { FileIcon } from '../assets/icons';
import { uploadChannelsDataThunk } from '../pages-client/Campaign/reducer';
import { useAppDispatch } from '../hooks/store';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface DzProps {
  text: string;
  accept: {};
  handleDrop: (acceptedFiles: FileWithPath[]) => void;
  platform: string;
  campaign_id: string;
  onSuccess?: (payload?: any) => void;
}

export const Dropzone = React.forwardRef(({ accept, text, handleDrop, platform, campaign_id, onSuccess }: DzProps, ref) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<FileWithPath[]>([]); // Store selected files
  const [uploading, setUploading] = useState(false); // Track upload status
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);

  const onDrop = useCallback((acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
    setFiles(acceptedFiles);
    setFileRejections(fileRejections);
    handleDrop?.(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
    maxSize: 5 * 1024, // 10KB
    minSize: 10,
  });

  // Upload files when the button is clicked
  const handleUpload = async () => {
    if (files.length === 0) {
      alert('Please select files before uploading!');
      return;
    }

    try {
      setUploading(true);

      const { payload } = await dispatch(uploadChannelsDataThunk({ file: files.pop() as File, platform, campaign_id })); // Wait for all uploads to complete
      setFiles([]);
      onSuccess?.(payload);
    } catch (error) {
      alert('Upload failed. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    openFileDialog: open,
    handleUpload,
    hasAcceptedFiles: files.length,
  }));

  const droppedFiles = files.map((file) => {
    return (
      <li key={file.path}>
        {file.name} - {file.size} bytes
      </li>
    );
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="container" style={{ ...style, flexDirection: 'column' }}>
      <div {...getRootProps()}>
        <FileIcon style={{ display: 'block', margin: 'auto' }} />
        <input {...getInputProps()} />
        <p hidden={files.length > 0}>{text}</p>
      </div>
      <aside>
        <ul>{droppedFiles}</ul>
      </aside>
      {fileRejections.length > 0 && (
        <div style={{ textAlign: 'left', color: 'red' }}>
          <ul>
            {fileRejections.map(({ file, errors }, index) => (
              <li key={index}>
                <ul>
                  {errors.map((error, errorIndex) => (
                    <li key={errorIndex}>{error.message}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="loader" hidden={!uploading}></div>
    </div>
  );
});
