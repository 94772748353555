import { makeRequest } from './http';

export const getInfluencersApi = async (search?: string) => {
  const response = await makeRequest({
    path: `/influencers${search ? '?search=' + search : ''}`,
    method: 'GET',
  });
  return response;
};

export const getInfluencersV2Api = async (data: ISearchChannels) => {
  const response = await makeRequest({
    path: `/v2/influencers`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const getInfluencerApi = async (influencer_id: string) => {
  const response = await makeRequest({
    path: `/influencers/${influencer_id}`,
    method: 'GET',
  });
  return response;
};

export const createInfluencerApi = async (data: ICreateInfluencer) => {
  const response = await makeRequest({
    path: `/influencers`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const updateInfluencerApi = async ({ influencer_id, data }: IUpdateInfluencer) => {
  const response = await makeRequest({
    path: `/influencers/${influencer_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const updateInfluencerByChannelIdApi = async ({ data }: IUpdateInfluencerByChannelId) => {
  const response = await makeRequest({
    path: `/influencers/profile/update_by_channel_id`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const deleteInfluencerApi = async (data: IDeleteInfluencer) => {
  const response = await makeRequest({
    path: `/influencers/delete`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const searchChannelsApi = async ({ platform, data }: ISearchChannelsPayload) => {
  const response = await makeRequest({
    path: `/channels/${platform}`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const addBulkToSelectedApi = async ({ platform, campaign_id, data }: ISearchChannelsPayload & { campaign_id: string }) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/${platform}/bulk/`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const getCommonApi = async () => {
  const response = await makeRequest({
    path: `/channels/common`,
    method: 'GET',
  });
  return response;
};

export const exportApi = async (data: IExport) => {
  const response = await makeRequest({
    path: `/export`,
    method: 'POST',
    params: JSON.stringify(data),
    exportData: true,
  });
  return response;
};

export const getAgenciesApi = async () => {
  const response = await makeRequest({
    path: `/agencies`,
    method: 'GET',
  });
  return response;
};

// SAVED CHANNEL LIST

export const getSavedChannelListApi = async () => {
  const response = await makeRequest({
    path: `/saved-lists`,
    method: 'GET',
  });
  return response;
};

export const saveChannelListApi = async (data: ISaveChannelList) => {
  const response = await makeRequest({
    path: `/saved-lists`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const addChannelListToExistApi = async ({ list_id, data }: IAddChannelListToExist) => {
  const response = await makeRequest({
    path: `/saved-lists/${list_id}/add`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const updateSavedChannelListApi = async ({ list_id, data }: IUpdateSavedChannelList) => {
  const response = await makeRequest({
    path: `/saved-lists/${list_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const deleteSavedChannelListApi = async (list_id: string) => {
  const response = await makeRequest({
    path: `/saved-lists/${list_id}`,
    method: 'DELETE',
  });
  return response;
};

// SAVED INFLUENCERS

export const deleteSavedInfluencersApi = async ({ list_id, data }: IDeleteSavedInfluencers) => {
  const response = await makeRequest({
    path: `/saved-lists/${list_id}/delete`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const getSavedChannelListInfluencersYoutubeApi = async (list_id: string) => {
  const response = await makeRequest({
    path: `/saved-lists/${list_id}/items/youtube`,
    method: 'GET',
  });
  return response;
};

export const getSavedChannelListInfluencersTwitchApi = async (list_id: string) => {
  const response = await makeRequest({
    path: `/saved-lists/${list_id}/items/twitch`,
    method: 'GET',
  });
  return response;
};

// TYPES

export type Platform = 'youtube' | 'twitch';

export type Ids = {
  youtube_channel_ids?: string[];
  twitch_channel_ids?: string[];
};

export interface ISearchChannels {
  keywords?: string[];
  limit?: number;
  offset?: number;
  order_by?: string;
  order_dir?: string | 'asc' | 'desc';
  filters?: any;
}

export interface ISearchChannelsPayload {
  platform: Platform;
  data: ISearchChannels;
}

export type SearchChannelsResponse = { data: any[]; total: {} };

export interface ICreateInfluencer {
  first_name?: string;
  last_name?: string;
  youtube_url?: string;
  twitch_url?: string;
  country?: string;
  language?: string;
  gender?: string;
  platform?: string;
  genre?: string;
  main_game?: string;
  agency_id?: string | number;
  [prop: string]: any;
}

export interface IUpdateInfluencer {
  influencer_id: string;
  data: ICreateInfluencer;
}

export interface IUpdateInfluencerByChannelId {
  data: {
    sm_platform: Platform;
    channel_description: string;
  } & ({ youtube_channel_id: string; twitch_channel_id?: string } | { youtube_channel_id?: string; twitch_channel_id: string });
}

export interface IDeleteInfluencer {
  ids: string[];
}

export interface ISaveChannelList {
  list_name: string;
  youtube_channel_ids?: string[];
  twitch_channel_ids?: string[];
}

export interface IAddChannelListToExist {
  list_id: string;
  data: Ids;
}

export interface IUpdateSavedChannelList {
  list_id: string;
  data: {
    list_name: string;
    [prop: string]: any;
  };
}

export interface IDeleteSavedInfluencers {
  list_id: string;
  data: Ids;
}

export interface IExport {
  data: any[];
  type?: 'csv' | 'xlsx';
  filename?: string;
  columns?: any[];
}
