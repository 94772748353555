import { FC, useEffect } from 'react';
import { ModalState } from 'components/Modal/reducer';

import { CloseIcon } from 'assets/icons';
import cn from 'classnames';
import { useTable, useRowSelect, useBlockLayout } from 'react-table';

interface IDeleteSelectedProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const UploadStats: FC<IDeleteSelectedProps> = ({ params, hideModal }) => {
  const { payload, handlePreload } = params;

  // Transform the payload into an array of objects with 'name' and 'value' keys
  const tableData = Object.entries(payload).map(([key, value]) => ({
    name: key,
    value: value,
  }));

  useEffect(() => {
    handlePreload();
  }, [handlePreload]);

  return (
    <div className="flex h-full flex-col">
      <div className="p-6 sm:p-8">
        <div className="relative flex items-start justify-center space-x-3">
          <p className="mb-2 text-2xl leading-10 font-semibold text-black-b1">Submit Channels</p>
          <div className="absolute -top-2 -right-2 z-10 flex h-7 items-center">
            <button type="button" className="text-black-b1 focus:outline-none" onClick={hideModal}>
              <span className="sr-only">Close panel</span>
              <CloseIcon aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="relative flex flex-col items-center">
          <Table
            columns={[
              {
                Header: 'File Summary',
                accessor: 'name',
              },
              {
                Header: '',
                accessor: 'value',
              },
            ]}
            data={tableData}
          />
        </div>
      </div>
    </div>
  );
};

function Table({ columns, data }: any) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    useBlockLayout
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="w-full border border-gray-g2 rounded-tl-[4px]">
        <thead className="sticky top-0 z-30 bg-black-b1 rounded-tl-[4px]">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ width: '100%' }}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  className={cn(
                    'group px-4 py-2 text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-black-b1 last:border-r-0',
                    column?.headerClassName || ''
                  )}
                  style={{ width: 'auto' }}
                >
                  <div className="flex items-center justify-between">{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white">
          {rows.slice(0, 10).map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="group border-b border-gray-g2 last:border-b-0 hover:shadow-s3" style={{ width: '100%' }}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={cn(
                        'relative whitespace-nowrap px-4 py-3.5 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2',
                        cell.column?.className || ''
                      )}
                      style={{ width: 'auto' }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
