import { FC, useMemo } from 'react';
import { useAppSelector } from 'hooks/store';
import { useLanguagesSelectOptions } from 'hooks/useLanguages';
import { useCountriesSelectOptions } from 'hooks/useCountries';
import { useSMPlatforms } from 'hooks/useSMPlatforms';
import { InputActionMeta } from 'react-select';
import { Controller, FieldError } from 'react-hook-form';
import cn from 'classnames';
import { Form, Input, Label, Popover, RangeSlider, SelectCheckbox, settingsSelectCheckboxMultiWithSearchStyles, ValueContainerWithSearch } from 'shared';
import { ArrowDownDropdownSolidIcon, ArrowUpDropdownSolidIcon, ResetIcon } from 'assets/icons';
import { isArray, isEqual } from 'lodash';
import { Platform } from 'services';
import { formatNumber } from 'utils/helper';
import {
  approvalSelectedStatuses,
  approvalManagementStatuses,
  outreachStatuses,
  contentFormatStatuses,
  contractStatuses,
  campaignStatuses,
  assetsStatuses,
} from 'utils/common';
import { selectCampaignLoadingToHeader } from 'pages/Campaign/reducer';
import { selectInfluencersLoadingToHeader } from 'pages/Influencers/reducer';
import { selectCampaignClientLoadingToHeader } from 'pages-client/Campaign/reducer';
import { selectAgencies } from 'pages/Admin/reducer';

const defaultSliderValue = { min: 0, max: 100 };

interface ISettingsProps {
  searchChannels: any;
  platform?: Platform;
  handleSubmit?: (value: any) => void;
}

interface ICampaignSettingsProps {
  campaign?: any;
  searchChannels: any;
  role: string;
  platform?: Platform;
  handleSubmit: (value: any) => void;
}

// TODO now settings exist of two componnets: YoutbueSettings and TwitchSettings. it's because there are no reinitialize prop in react-hook-form
export const Settings: FC<ISettingsProps> = (props) => {
  const { platform = 'youtube' } = props;
  return platform === 'youtube' ? <YoutubeSettings {...props} /> : <TwitchSettings {...props} />;
};

export const YoutubeSettings: FC<ISettingsProps> = ({ searchChannels = { filters: {} }, platform = 'youtube', handleSubmit }) => {
  const countries = useCountriesSelectOptions();
  const languages = useLanguagesSelectOptions();

  const filters = useMemo(() => searchChannels?.filters?.[platform] || {}, [platform]);

  const defaultValues: any = useMemo(
    () => ({
      subscribers: filters?.subscribers || [],
      median_views_20: filters?.median_views_20 || [],
      engagement_score: filters?.engagement_score // TODO temp, if engagement_score have value that length >1
        ? {
            min: Math.round(searchChannels.filters[platform]?.engagement_score[0] * 100) || defaultSliderValue.min,
            max: Math.round(searchChannels.filters[platform]?.engagement_score[1] * 100) || defaultSliderValue.max,
          }
        : defaultSliderValue,
      true_reach_20: filters?.true_reach_20
        ? {
            min: Math.round(searchChannels.filters[platform]?.true_reach_20[0] * 100) || defaultSliderValue.min,
            max: Math.round(searchChannels.filters[platform]?.true_reach_20[1] * 100) || defaultSliderValue.max,
          }
        : defaultSliderValue,
      language: filters?.language || [],
      country: filters?.country || [],
    }),
    [filters]
  );

  const settingsConfig: any[] = useMemo(
    () => [
      {
        name: 'subscribers',
        label: 'Subscribers',
        type: 'tags',
        config: {
          type: '00',
        },
      },
      {
        name: 'median_views_20',
        label: 'Average Views',
        type: 'tags',
        config: {
          type: '00',
        },
      },
      {
        name: 'engagement_score',
        label: 'Engagement Score',
        type: 'slider',
      },
      {
        name: 'true_reach_20',
        label: 'True Reach',
        type: 'slider',
      },
      {
        name: 'language',
        label: 'Language',
        type: 'checkbox',
        options: languages,
      },
      {
        name: 'country',
        label: 'Country',
        type: 'checkbox',
        options: countries,
      },
    ],
    [languages, countries]
  );
  return (
    <SettingsForm
      defaultValues={defaultValues}
      platform={platform}
      filters={searchChannels?.filters?.[platform] || {}}
      settingsConfig={settingsConfig}
      handleSubmit={handleSubmit}
    />
  );
};

export const TwitchSettings: FC<ISettingsProps> = ({ searchChannels = { filters: {} }, platform = 'twitch', handleSubmit }) => {
  const countries = useCountriesSelectOptions();
  const languages = useLanguagesSelectOptions();

  const filters = useMemo(() => searchChannels?.filters?.[platform] || {}, [platform]);

  const defaultValues: any = useMemo(
    () => ({
      followers: filters?.followers || [],
      aaccv_20: filters?.aaccv_20 || [],
      average_peak_20: filters?.average_peak_20 || [],
      language: filters?.language || [],
      country: filters?.country || [],
    }),
    [filters]
  );
  const settingsConfig: any[] = useMemo(
    () => [
      {
        name: 'followers',
        label: 'Followers',
        type: 'tags',
        config: {
          type: '00',
        },
      },
      {
        name: 'aaccv_20',
        label: 'Average CCV',
        type: 'tags',
        config: {
          type: '01',
        },
      },
      {
        name: 'average_peak_20',
        label: 'Avg. Peak Viewers',
        type: 'tags',
        config: {
          type: '01',
        },
      },
      {
        name: 'language',
        label: 'Language',
        type: 'checkbox',
        options: languages,
      },
    ],
    [languages]
  );
  return (
    <SettingsForm
      defaultValues={defaultValues}
      platform={platform}
      filters={searchChannels?.filters?.[platform] || {}}
      settingsConfig={settingsConfig}
      handleSubmit={handleSubmit}
    />
  );
};

export const CampaignSelectedSettings: FC<ICampaignSettingsProps> = (props) => {
  switch (props.platform) {
    default:
    case 'youtube':
      return <CampaignSelectedYoutubeSettings {...props} />;
    case 'twitch':
      return <CampaignSelectedTwitchSettings {...props} />;
  }
};

export const CampaignSelectedYoutubeSettings: FC<ICampaignSettingsProps> = ({ searchChannels = { filters: {} }, platform = 'youtube', role, handleSubmit }) => {
  const countries = useCountriesSelectOptions();
  const languages = useLanguagesSelectOptions();
  const agencies = useAppSelector(selectAgencies).map((obj) => ({ value: obj.agency_id, label: obj.agency_name }));

  const filters = useMemo(() => searchChannels?.filters?.[platform] || {}, [platform]);

  const defaultValues = useMemo(
    () => ({
      subscribers: filters?.subscribers || [],
      median_views_20: filters?.median_views_20 || [],
      engagement_score: filters?.engagement_score // TODO temp, if engagement_score have value that length >1
        ? {
            min: Math.round(searchChannels.filters[platform]?.engagement_score[0] * 100) || defaultSliderValue.min,
            max: Math.round(searchChannels.filters[platform]?.engagement_score[1] * 100) || defaultSliderValue.max,
          }
        : defaultSliderValue,
      true_reach_20: filters?.true_reach_20
        ? {
            min: Math.round(searchChannels.filters[platform]?.true_reach_20[0] * 100) || defaultSliderValue.min,
            max: Math.round(searchChannels.filters[platform]?.true_reach_20[1] * 100) || defaultSliderValue.max,
          }
        : defaultSliderValue,
      language: filters?.language || [],
      country: filters?.country || [],
      approval: filters?.approval || [],
      outreach_status: filters?.outreach_status || [],
      content_format: filters?.content_format || [],
      agency_id: filters?.agency_id || [],
    }),
    [filters]
  );

  const settingsConfig: any[] = useMemo(() => {
    const config = [
      {
        name: 'subscribers',
        label: 'Subscribers',
        type: 'tags',
        config: { type: '00' },
      },
      {
        name: 'median_views_20',
        label: 'Average Views',
        type: 'tags',
        config: { type: '00' },
      },
      {
        name: 'engagement_score',
        label: 'Engagement Score',
        type: 'slider',
      },
      {
        name: 'true_reach_20',
        label: 'True Reach',
        type: 'slider',
      },
      {
        name: 'language',
        label: 'Language',
        type: 'checkbox',
        options: languages,
      },
      {
        name: 'country',
        label: 'Country',
        type: 'checkbox',
        options: countries,
      },
      {
        name: 'approval',
        label: 'Approval',
        type: 'checkbox',
        options: approvalSelectedStatuses,
      },
      {
        name: 'outreach_status',
        label: 'Outreach Status',
        type: 'checkbox',
        options: outreachStatuses,
      },
      {
        name: 'content_format',
        label: 'Content Format',
        type: 'checkbox',
        options: contentFormatStatuses,
      },
    ];

    if (role !== 'user') {
      config.push({
        name: 'agency_id',
        label: 'Agency',
        type: 'checkbox',
        options: agencies,
      });
    }

    return config;
  }, [role, languages, countries, approvalSelectedStatuses, outreachStatuses, contentFormatStatuses, agencies]);

  return (
    <SettingsForm
      defaultValues={defaultValues}
      platform={platform}
      filters={searchChannels?.filters?.[platform] || {}}
      settingsConfig={settingsConfig}
      handleSubmit={handleSubmit}
    />
  );
};

export const CampaignSelectedTwitchSettings: FC<ICampaignSettingsProps> = ({ searchChannels = { filters: {} }, platform = 'twitch', role, handleSubmit }) => {
  const countries = useCountriesSelectOptions();
  const languages = useLanguagesSelectOptions();
  const agencies = useAppSelector(selectAgencies).map((obj) => ({ value: obj.agency_id, label: obj.agency_name }));

  const filters = useMemo(() => searchChannels?.filters?.[platform] || {}, [platform]);

  const defaultValues = useMemo(
    () => ({
      followers: filters?.followers || [],
      aaccv_20: filters?.aaccv_20 || [],
      average_peak_20: filters?.average_peak_20 || [],
      language: filters?.language || [],
      country: filters?.country || [],
      approval: filters?.approval || [],
      outreach_status: filters?.outreach_status || [],
      content_format: filters?.content_format || [],
      agency_id: filters?.agency_id || [],
    }),
    [filters]
  );

  const settingsConfig = useMemo(() => {
    const config = [
      {
        name: 'followers',
        label: 'Followers',
        type: 'tags',
        config: { type: '00' },
      },
      {
        name: 'aaccv_20',
        label: 'Average CCV',
        type: 'tags',
        config: { type: '01' },
      },
      {
        name: 'average_peak_20',
        label: 'Avg. Peak Viewers',
        type: 'tags',
        config: { type: '01' },
      },
      {
        name: 'language',
        label: 'Language',
        type: 'checkbox',
        options: languages,
      },
      {
        name: 'country',
        label: 'Country',
        type: 'checkbox',
        options: countries,
      },
      {
        name: 'approval',
        label: 'Approval',
        type: 'checkbox',
        options: approvalSelectedStatuses,
      },
      {
        name: 'outreach_status',
        label: 'Outreach Status',
        type: 'checkbox',
        options: outreachStatuses,
      },
      {
        name: 'content_format',
        label: 'Content Format',
        type: 'checkbox',
        options: contentFormatStatuses,
      },
    ];

    if (role !== 'user') {
      config.push({
        name: 'agency_id',
        label: 'Agency',
        type: 'checkbox',
        options: agencies,
      });
    }

    return config;
  }, [role, languages, countries, approvalSelectedStatuses, outreachStatuses, contentFormatStatuses, agencies]);

  return (
    <SettingsForm
      defaultValues={defaultValues}
      platform={platform}
      filters={searchChannels?.filters?.[platform] || {}}
      settingsConfig={settingsConfig}
      handleSubmit={handleSubmit}
    />
  );
};

export const CampaignEngagementSettings: FC<ICampaignSettingsProps> = ({ campaign, searchChannels = { filters: {} }, role, handleSubmit }) => {
  const platforms = useSMPlatforms(campaign);
  const agencies = useAppSelector(selectAgencies).map((obj) => ({ value: obj.agency_id, label: obj.agency_name }));

  const filters = searchChannels?.filters || {};

  const defaultValues = useMemo(
    () => ({
      sm_platform: filters?.sm_platform || [],
      approval: filters?.approval || [],
      outreach_status: filters?.outreach_status || [],
      content_format: filters?.content_format || [],
      contract_status: filters?.contract_status || [],
      agency_id: filters?.agency_id || [],
    }),
    [filters]
  );

  const settingsConfig = useMemo(() => {
    const config = [
      {
        name: 'sm_platform',
        label: 'SM Platform',
        type: 'checkbox',
        options: Object.values(platforms),
      },
      {
        name: 'approval',
        label: 'Approval',
        type: 'checkbox',
        options: approvalSelectedStatuses,
      },
      {
        name: 'outreach_status',
        label: 'Outreach Status',
        type: 'checkbox',
        options: outreachStatuses,
      },
      {
        name: 'content_format',
        label: 'Content Format',
        type: 'checkbox',
        options: contentFormatStatuses,
      },
      {
        name: 'contract_status',
        label: 'Contract Status',
        type: 'checkbox',
        options: contractStatuses,
      },
    ];

    if (role !== 'user') {
      config.push({
        name: 'agency_id',
        label: 'Agency',
        type: 'checkbox',
        options: agencies,
      });
    }

    return config;
  }, [role, platforms, approvalManagementStatuses, outreachStatuses, contentFormatStatuses, contractStatuses, agencies]);

  return <SettingsForm defaultValues={defaultValues} platform="youtube" filters={filters} settingsConfig={settingsConfig} handleSubmit={handleSubmit} />;
};

export const CampaignManagementSettings: FC<ICampaignSettingsProps> = ({ campaign, searchChannels = { filters: {} }, role, handleSubmit }) => {
  const platforms = useSMPlatforms(campaign);
  const agencies = useAppSelector(selectAgencies).map((obj) => ({ value: obj.agency_id, label: obj.agency_name }));

  const filters = searchChannels?.filters || {};

  const defaultValues = useMemo(
    () => ({
      sm_platform: filters?.sm_platform || [],
      approval_management: filters?.approval_management || [],
      content_format: filters?.content_format || [],
      contract_status: filters?.contract_status || [],
      agency_id: filters?.agency_id || [],
      campaign_status: filters?.campaign_status || [],
      assets_status: filters?.assets_status || [],
    }),
    [filters]
  );

  const settingsConfig = useMemo(() => {
    const config = [
      {
        name: 'sm_platform',
        label: 'SM Platform',
        type: 'checkbox',
        options: Object.values(platforms),
      },
      {
        name: 'approval_management',
        label: 'Approval',
        type: 'checkbox',
        options: approvalManagementStatuses,
      },
      {
        name: 'content_format',
        label: 'Content Format',
        type: 'checkbox',
        options: contentFormatStatuses,
      },
      {
        name: 'contract_status',
        label: 'Contract Status',
        type: 'checkbox',
        options: contractStatuses,
      },
    ];

    if (role !== 'user') {
      config.push({
        name: 'agency_id',
        label: 'Agency',
        type: 'checkbox',
        options: agencies,
      });
    }

    config.push(
      {
        name: 'campaign_status',
        label: 'Campaign Status',
        type: 'checkbox',
        options: campaignStatuses,
      },
      {
        name: 'assets_status',
        label: 'Assets Status',
        type: 'checkbox',
        options: assetsStatuses,
      }
    );

    return config;
  }, [role, platforms, approvalManagementStatuses, contentFormatStatuses, contractStatuses, agencies, campaignStatuses, assetsStatuses]);

  return <SettingsForm defaultValues={defaultValues} platform="twitch" filters={filters} settingsConfig={settingsConfig} handleSubmit={handleSubmit} />;
};

interface ISettingsFormProps {
  defaultValues: any;
  platform: Platform;
  settingsConfig: any[];
  filters: any;
  handleSubmit?: (value: any) => void;
}

export const SettingsForm: FC<ISettingsFormProps> = ({ defaultValues, platform, filters, settingsConfig, handleSubmit = () => null }) => {
  const influencerLoading = useAppSelector(selectInfluencersLoadingToHeader);
  const campaignLoading = useAppSelector(selectCampaignLoadingToHeader);
  const campaignLoaingClient = useAppSelector(selectCampaignClientLoadingToHeader);

  const loading = campaignLoading || influencerLoading; // || campaignLoaingClient

  const onInputChange = (inputValue: string, { action, prevInputValue }: InputActionMeta) => {
    if (action === 'input-change') return inputValue;
    if (action === 'menu-close') return '';

    return prevInputValue;
  };

  return (
    <Form
      className="flex flex-col bg-white rounded-[4px] p-3 space-y-3 sm:space-y-0 sm:space-x-3 sm:flex-row sm:items-end"
      defaultValues={defaultValues}
      // validationType={ValidationTypes.AdminAddInfluencer}
      onSubmit={(values, e, { reset }) => {
        console.log('SUMBIT', values);
        const result: any = {};
        Object.keys(defaultValues).forEach((key: string) => {
          if (values[key] && ((isArray(values[key]) && values[key].length) || (Object.keys(values[key]).length && !isEqual(values[key], defaultSliderValue))))
            result[key] = key === 'engagement_score' || key === 'true_reach_20' ? [values[key].min / 100, values[key].max / 100] : values[key];
        });

        handleSubmit(result);
        if (!Object.keys(result).length) reset(values);
      }}
    >
      {({ control, watch, reset, setError, clearErrors, formState: { dirtyFields, errors } }) => {
        // Check 'Apply' button disable
        let isActiveApply = Object.keys(filters).length || Object.keys(dirtyFields).length;

        return (
          <>
            <div className="flex-1">
              <div className={cn('grid gap-3 grid-cols-1 sm:grid-cols-3', platform === 'youtube' ? 'md:grid-cols-6' : ' md:grid-cols-4')}>
                {settingsConfig.map((d: any) => {
                  const allOptions =
                    d.name === 'language' || d.name === 'country' || d.name === 'sm_platform' ? [{ value: 'all', label: 'All' }, ...d.options] : d.options;

                  return (
                    <div key={d.name}>
                      <Label htmlFor={d.name} label={d.label} error={errors[d.name]} />
                      {d.type === 'checkbox' || !d.type ? (
                        <Controller
                          name={d.name}
                          control={control}
                          render={({ field }: any) => (
                            <SelectCheckbox
                              {...field}
                              styles={settingsSelectCheckboxMultiWithSearchStyles}
                              placeholder="Select"
                              options={allOptions}
                              error={errors[field.name]}
                              isSearchable={true}
                              isClearable={true}
                              onInputChange={onInputChange}
                              defaultValue={allOptions.filter((option: any) => field.value.includes(option.value)) || null}
                              value={allOptions.filter((option: any) => field.value.includes(option.value)) || null}
                              onChange={(options: any[], actionMeta: any) => {
                                const isAll = options.some((option: any) => option.value === 'all');

                                // Clear
                                if (actionMeta.action === 'clear') field.onChange([]);
                                // Selected 'All' option
                                else if (actionMeta.option.value === 'all' && actionMeta.action === 'select-option')
                                  field.onChange(allOptions.map((d: any) => d.value));
                                // Deselected 'All' option
                                else if (actionMeta.option.value === 'all' && actionMeta.action === 'deselect-option') field.onChange([]);
                                // If deselect something option when 'All' selected
                                else if (isAll) field.onChange(options.filter((option: any) => option.value !== 'all').map((d: any) => d.value));
                                // Select 'All' option if checked all options without 'All'
                                else if (!isAll && options.length === d.options.length) field.onChange(allOptions.map((d: any) => d.value));
                                // Select/deselect other options
                                else field.onChange(options.map((d: any) => d.value));
                              }}
                              components={{
                                ValueContainer: ValueContainerWithSearch,
                                MultiValue: () => null,
                                MultiValueLabel: () => null,
                                MultiValueRemove: () => null,
                              }}
                            />
                          )}
                        />
                      ) : d.type === 'slider' ? (
                        <Popover
                          isTransition={false}
                          panelClass="left-0"
                          content={
                            <span className="flex w-[15rem] h-full">
                              <div className="w-full mt-6 p-4">
                                <Controller name={d.name} control={control} render={({ field }: any) => <RangeSlider {...field} />} />
                              </div>
                            </span>
                          }
                        >
                          {({ open }) => (
                            <button
                              type="button"
                              className={cn(
                                'flex items-center justify-between cursor-default h-[42px] w-full pl-3 pr-2 border rounded-[4px] ring-0 focus:outline-none',
                                open ? 'border-blue-b1 hover:border-blue-b1' : 'border-gray-g2'
                              )}
                            >
                              <p className="text-xs leading-6 text-black-b1">
                                {!isEqual(watch(d.name), defaultSliderValue)
                                  ? // ? `${
                                    //     watch(d.name) > 0
                                    //       ? 0 + '-' + watch(d.name)
                                    //       : 0
                                    //   }%`
                                    `${watch(d.name).min} - ${watch(d.name).max}%`
                                  : 'Select'}
                              </p>
                              {open ? <ArrowUpDropdownSolidIcon /> : <ArrowDownDropdownSolidIcon />}
                            </button>
                          )}
                        </Popover>
                      ) : d.type === 'tags' ? (
                        <Popover
                          isTransition={false}
                          panelClass="left-0"
                          content={
                            <span className="flex w-[15rem] h-full">
                              <Controller
                                name={d.name}
                                control={control}
                                render={({ field }: any) => <TagsSettings {...field} setError={setError} clearErrors={clearErrors} config={d.config} />}
                              />
                            </span>
                          }
                        >
                          {({ open }) => (
                            <button
                              type="button"
                              className={cn(
                                'flex items-center justify-between cursor-default h-[42px] w-full pl-3 pr-2 border rounded-[4px] ring-0 focus:outline-none',
                                open ? '!border-blue-b1 hover:!border-blue-b1' : 'border-gray-g2',
                                errors[d.name] ? '!border-red-r1 focus:!border-red-r1' : 'border-gray-g2'
                              )}
                            >
                              <p className="text-xs leading-6 text-black-b1">
                                {watch(d.name).length
                                  ? `${formatNumber(watch(d.name)[0], watch(d.name)[0] >= 10000 ? '0,0a' : undefined)}
                                ${watch(d.name)[1] ? '- ' + formatNumber(watch(d.name)[1], watch(d.name)[1] > 10000 ? '0,0a' : undefined) : ''}`
                                  : 'Select'}
                              </p>
                              {open ? <ArrowUpDropdownSolidIcon /> : <ArrowDownDropdownSolidIcon />}
                            </button>
                          )}
                        </Popover>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <button
                type="button"
                className="inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent bg-gray-g1 text-blue-b1 shadow-sm hover:bg-gray-g1 focus:outline-none focus:ring-0 focus:ring-blue-b1 focus:ring-offset-0"
                onClick={() => {
                  reset(
                    Object.keys(defaultValues).reduce(
                      (acc: any, cur: any) => ({
                        ...acc,
                        [cur]: isArray(defaultValues[cur]) ? [] : defaultSliderValue,
                      }),
                      {}
                    )
                  );
                  handleSubmit({});
                }}
              >
                <ResetIcon aria-hidden="true" />
              </button>
              <button
                type="submit"
                className={cn(
                  'w-full sm:w-auto inline-flex items-center justify-center h-[42px] rounded-[4px] border border-blue-b1 px-10 uppercase text-sm leading-6 font-medium text-blue-b1 active:border-transparent hover:bg-blue-bl focus:outline-none focus:ring-0 focus:ring-offset-0',
                  { disabled: loading || !isActiveApply }
                )}
                disabled={loading || !isActiveApply}
              >
                Apply
              </button>
            </div>
          </>
        );
      }}
    </Form>
  );
};

const tags: any = {
  '00': [
    {
      id: '0<100',
      value: ['0', '100000'],
      label: 'under 100K',
    },
    {
      id: '100<500',
      value: ['100000', '500000'],
      label: '100K - 500K',
    },
    {
      id: '500<1000',
      value: ['500000', '1000000'],
      label: '500K - 1M',
    },
    {
      id: '1000<5000',
      value: ['1000000', '5000000'],
      label: '1M - 5M',
    },
    {
      id: '>5000',
      value: ['5000000'],
      label: '5M and above',
    },
  ],
  '01': [
    {
      id: '0<1000',
      value: ['0', '1000'],
      label: 'under 1000',
    },
    {
      id: '1000<5000',
      value: ['1000', '5000'],
      label: '1000 - 5000',
    },
    {
      id: '5000<10000',
      value: ['5000', '10000'],
      label: '5000 - 10000',
    },
    {
      id: '10000<50000',
      value: ['10000', '50000'],
      label: '10K - 50K',
    },
    {
      id: '>50000',
      value: ['50000'],
      label: '50K and above',
    },
  ],
};

interface ITagsSettings {
  name: string;
  value: any;
  config: any;
  onChange: (value: string[]) => void;
  setError: (name: string, error: FieldError, config?: { shouldFocus?: boolean }) => void;
  clearErrors: (name?: string | string[]) => void;
  className?: string;
  valueClass?: string;
  isTags?: boolean;
}

export const TagsSettings: FC<ITagsSettings> = ({
  name,
  value,
  config,
  onChange = () => {},
  setError,
  clearErrors,
  className = '',
  valueClass = '',
  isTags = true,
}) => {
  const tagObject: any = useMemo(() => tags[config.type].find((d: any) => isEqual(d.value, value)) || {}, [value, config]);

  const valueString = value.length ? formatNumber(value[0]) + (value[1] ? ' - ' + formatNumber(value[1]) : '') : 0;

  return (
    <div className={cn('p-3', className)}>
      {isTags ? (
        <div className="flex flex-wrap mb-1">
          {tags[config.type].map((d: any) => (
            <div
              key={d.id}
              className={cn(
                'flex items-center h-[28px] cursor-pointer mb-2 mr-2 px-2 rounded-[4px]',
                d.id === tagObject?.id ? ' text-white bg-black-b1' : 'text-black-b1 bg-gray-g1'
              )}
              onClick={() => onChange(d.value)}
            >
              <p className="text-xs leading-6">{d.label}</p>
            </div>
          ))}
        </div>
      ) : null}
      <p className={cn('text-sm leading-6 text-gray-g2', valueClass)}>{valueString}</p>
      <div className="flex items-center mt-1">
        <Input
          className="!w-unset !h-[42px]"
          type="number"
          min={0}
          value={value[0] === 0 ? 0 : value[0] ? value[0] : ''}
          onChange={(e: any) => {
            const _value = e.target.value;
            const arr = [_value];

            if (value[1]) arr.push(value[1]);
            onChange(arr);

            if (value[1] && parseFloat(_value) > parseFloat(value[1])) {
              setError(name, { type: 'error' });
            } else clearErrors(name);
          }}
          onBlur={(e: any) => {
            if (!value[1] && !e.target.value) onChange([]);
            else {
              const arr = [parseFloat(e.target.value) > 0 ? e.target.value : 0];
              if (value[1]) arr.push(value[1]);
              onChange(arr);
            }
          }}
        />
        <p className="px-2 text-sm leading-6 text-black-b1">-</p>
        <Input
          className="!w-unset !h-[42px]"
          type="number"
          min={0}
          error={value[1] ? parseFloat(value[0]) > parseFloat(value[1]) : false}
          value={value[1] || ''}
          onChange={(e: any) => {
            const _value = e.target.value;

            if (!value[0]) onChange([0, _value]);
            else onChange([value[0], _value]);

            if (_value && parseFloat(value[0]) > parseFloat(_value)) {
              setError(name, { type: 'error' });
            } else clearErrors(name);
          }}
          onBlur={(e: any) => {
            if (!value[0] && !e.target.value) onChange([]);
          }}
        />
      </div>
    </div>
  );
};
